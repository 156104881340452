// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bN_kC";
export var caseStudyBackground__lineColor = "bN_kz";
export var caseStudyHead__imageAbsolute = "bN_kw";
export var caseStudyHead__imageWrapper = "bN_kv";
export var caseStudyProjectsSection = "bN_kD";
export var caseStudyQuote__bgLight = "bN_kK";
export var caseStudyQuote__bgRing = "bN_kx";
export var caseStudySolution__ring = "bN_kH";
export var caseStudyTech = "bN_kL";
export var caseStudy__bgDark = "bN_ks";
export var caseStudy__bgLight = "bN_kr";